import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageBackground from "../img/hero-image-short-dark-bottom.png"
import $ from "jquery"
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Fragment = React.Fragment;

class Body extends React.Component {
  componentDidMount() {
    var comp = this;
    $(function() {
      $(".needs-validation").submit(function(event) {
        var form = $(this).get(0);

        if (form.checkValidity() !== false) {
          form.classList.add("was-validated");

          comp.props.googleReCaptchaProps.executeRecaptcha('contact_us').then(token => {
            var gRecaptchaResponse = $("input[name=g-recaptcha-response]");

            if (gRecaptchaResponse.length < 1) {
              gRecaptchaResponse = $(
                '<input type="hidden" name="g-recaptcha-response" />'
              );
              $("#contactForm").append(gRecaptchaResponse);
            }

            gRecaptchaResponse.val(token);

            $.ajax({
              type: "POST",
              url: $("#contactForm").attr("action"),
              data: $("#contactForm").serialize(),
              success: function(response) {
                console.log(response);
                if (response.success) {
                  window.location.href = response.page;
                }
              }
            });
          });
        }

        event.preventDefault();
        event.stopPropagation();

        return false;
      });
    });
  }

  render() { return (
      <div className="container body-content">
        <div className="row">
            <div className="col-lg-9">
              <form
                id="contactForm"
                className="form form-horizontal needs-validation"
                method="post"
                action="/api/contact-form">
                <div className="form-group form-group-lg">
                  <label htmlFor="Contact_Name" className="col-sm-3 right">Name:</label>
                  <div className="col-sm-9">
                    <input
                      id="Contact_Name"
                      name="Contact_Name"
                      type="text"
                      className="form-control"
                      required />
                    <div className="invalid-feedback">Required</div>
                  </div>
                </div>
                <div className="form-group form-group-lg">
                  <label htmlFor="Contact_Phone" className="col-sm-3 right">Phone:</label>
                  <div className="col-sm-9">
                    <input
                      type="tel"
                      id="Contact_Phone"
                      name="Contact_Phone"
                      className="form-control"
                      required />
                    <div className="invalid-feedback">Required</div>
                  </div>
                </div>
                <div className="form-group form-group-lg">
                  <label htmlFor="Contact_Email" className="col-sm-3 right">Email:</label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      id="Contact_Email"
                      name="Contact_Email"
                      className="form-control"
                      required />
                    <div className="invalid-feedback">Required</div>
                  </div>
                </div>
                <div className="form-group form-group-lg">
                  <label htmlFor="Contact_PreferredContactMethod" className="col-sm-3 right">Contact Me Via:</label>
                  <div className="col-sm-9">
                    <select
                      id="Contact_PreferredContactMethod"
                      name="Contact_PreferredContactMethod"
                      className="form-control"
                      required>
                        <option>Please select one</option>
                        <option>Email</option>
                        <option>Phone</option>
                    </select>
                    <div className="invalid-feedback">Required</div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Contact_Message" className="col-sm-3 right">Your Message:</label>
                  <div className="col-sm-9">
                    <textarea
                      name="Contact_Message"
                      id="Contact_Message"
                      rows="6"
                      className="form-control"
                      required></textarea>
                    <div className="invalid-feedback">Required</div>
                  </div>
                </div>

                <div className="form-group form-group-lg">
                  <div className="col-sm-offset-3 col-sm-9">
                    <button className="g-recaptcha btn btn-default">Send</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-3">
              <span>If you have any questions or need immediate help, dont hesitate to call:
                <address>
                  <abbr title="Phone">P:</abbr>
                  717.554.0689
                </address>
              </span>
            </div>
          </div>
      </div>
    );
  }
}

const BodyWithGoogleReCaptcha = withGoogleReCaptcha(Body);

export default () => (
<Fragment>
  <Header pageType="page"
          pageTitle="A.O.M."
          headerImg={PageBackground} />
  <GoogleReCaptchaProvider reCaptchaKey="6LfFF4UUAAAAAL1R-4I4q69QQN6f7IWqE7-YdQ3_">
    <BodyWithGoogleReCaptcha />
  </GoogleReCaptchaProvider>
  <Footer />
</Fragment>
)